'use es6';

import publicLaboratoryClient from 'ecosystem-marketplace-solutions-base/clients/publicLaboratoryClient';
const startTime = performance.now();
const locale = window.I18N_MANUAL_LANG || 'en';

// public early requester for service definitions
// the services are required to build the routes.
// If we don't have them early enough the "all who wander" page will appear for a quick moment.
window.quickFetch.makeEarlyRequest('services', {
  url: window.quickFetch.getApiUrl(`/service-catalog/public/v1/services?language=${locale}`, true),
  dataType: 'json',
  contentType: 'application/json',
  type: 'GET',
  whenFinished() {
    if (window.newrelic) {
      window.newrelic.setCustomAttribute('services-early-success-time', performance.now() - startTime);
    }
  }
});
publicLaboratoryClient.resolve();